import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import * as styles from "../../components/index.module.css"
const IndexPage = ({ data }) => {
    const {
        srcWebp,
        srcSetWebp,
        srcSet,
        src,
        presentationHeight,
        presentationWidth,
        } = data.file.childImageSharp.fluid;
        return (
  <Layout>
    <div className={styles.textCenter}>
    <amp-img
        src={srcWebp}
        srcset={srcSetWebp}
        width={presentationWidth}
        height={presentationHeight}
        alt="Dosugcz-Ru" 
        title="Досугцз-Ру"
        layout="intrinsic"
      >
        <div fallback>
          <amp-img
            src={src}
            srcset={srcSet}
            width={presentationWidth}
            height={presentationHeight}
            alt="Dosugcz-Ru" 
            title="Досугцз-Ру"
            layout="fill"
          />
          </div>
      </amp-img>
      <p className={styles.middletext}>Не знаешь, куда переехал Досуг цз? Заходи всегда по новому адресу!</p>
      <h1>Кнопка для перехода на сайт Dosug.cz</h1>
      <a href="/cgi-bin/cz.pl" className={styles.button}>Вход на сайт 18+</a>
      <p className={styles.savepage}>Пожалуйста, сохраните эту страницу в закладки вашего браузера, чтобы всегда иметь доступ к актуальной версии сайта.</p>
      <div className={styles.social}>
      <a href="https://twitter.com/Dosugcz_ru" aria-label="Присоединяйтесь к нам в Twitter"><svg width="48" height="48" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z" fill="#6563ff"></path></svg></a>
      <a href="https://t.me/DosugOfficialle" aria-label="Присоединяйтесь к нам в Telegram"><svg width="48" height="48" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11.99432,2a10,10,0,1,0,10,10A9.99917,9.99917,0,0,0,11.99432,2Zm3.17951,15.15247a.70547.70547,0,0,1-1.002.3515l-2.71467-2.10938L9.71484,17.002a.29969.29969,0,0,1-.285.03894l.334-2.98846.01069.00848.00683-.059s4.885-4.44751,5.084-4.637c.20147-.189.135-.23.135-.23.01147-.23053-.36152,0-.36152,0L8.16632,13.299l-2.69549-.918s-.414-.1485-.453-.475c-.041-.324.46649-.5.46649-.5l10.717-4.25751s.881-.39252.881.25751Z" fill="#6563ff"/></svg></a>   
          </div>
      <p className={styles.draw}><em>Вниманию сотрудников государственных организаций: на данном сайте исключительно лишь красная кнопка, нажатию по которой пользователя перемещает на случайный веб-адрес. Обращаем ваше внимание на то, что данный ресурс абсолютно безопасен и надеемся на то, что никакие незаконные действия не будут предприниматься в отношении данного сайта.</em></p>
      <p className={styles.text}>Dosug был и остается наиболее популярным порталом рекламы интим услуг. Число посетителей Dosug переваливает за 200 000 в сутки. Этот показатель сайта Dosug перебить в ближайшее время никто не сможет. К тому же аудитория постоянно растёт.</p>
      <p className={styles.text}>Если брать выбор представительниц прекрасного пола, то и здесь Dosug - у нет равных. На портале постоянно размещаются в среднем 1500 анкет девушек со всей России, причем в отличие от множества других ресурсов, на Dosug нет «мертвых» анкет. Если вы увидели на сайте девушку и позвонили ей, вам обязательно ответят и скорее всего, она. Поэтому лозунг dosug.cz - выбери себе развлечение - всегда актуален.</p>
      <p className={styles.text}>На Dosug.cz собрана абсолютно вся интересующая вас информация об истории развития и существования величайшего досугового сервера DOSUG NU, который часто называют досуг «ню» или «ну». Оба названия, хотя звучат и выглядят по-разному, но несут одинаковую смысловую нагрузку. Обратите внимание на частицу ню (ну), которая означает фактор отличного отдыха при просмотре сайта Dosug.</p>
      <p className={styles.text}>Стоит отметить, что даже в английском языке частица nu тоже является символом обнаженности и подразумевает под собой понятие определенного стиля жизни, а именно жизни без одежды в стиле Dosug nu. В особенности жизнь без одежды обожают молодые девушки, которые стараются по возможности обходиться без сильно мешающего нижнего белья. К данной категории относятся и девушки представленные на этом сайте.</p>
      <p className={styles.text}>Благодаря самой откровенной и абсолютно обнаженной профессиональной фотосессии для Dosug в самых лучших фото студиях все посетители могут детально рассмотреть и прикинуть все характеристики лучших фото моделей, а также оценить части тела каждой девушки. На сайте собрана крупнейшая база данных из более чем 1000 представительниц на любой вкус и цвет.</p>
      <p className={styles.text}>Далее представлены вариации названий Dosug - «dosug.nu», «dosug.cz», «досуг.ню», «досуг.цз», «досуг.cz», «досуг.ру», «dosug.ru». Это всё одно и тоже, суть сайта Dosug от этого не меняется.</p><br></br>
      <a href="/dosugcz-ru.atom" aria-label="Feed"><svg width="30" height="30" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M13.98,30.77c0,1.17-0.41,2.17-1.23,2.99C11.94,34.59,10.94,35,9.77,35c-1.17,0-2.16-0.41-2.98-1.23 c-0.82-0.82-1.23-1.82-1.23-2.99s0.41-2.17,1.23-2.99c0.82-0.82,1.81-1.23,2.98-1.23c1.17,0,2.16,0.41,2.98,1.23 C13.57,28.6,13.98,29.6,13.98,30.77z M25.21,33.48c0.03,0.41-0.1,0.76-0.37,1.06C24.57,34.85,24.23,35,23.8,35h-2.96 c-0.37,0-0.68-0.12-0.94-0.36c-0.26-0.24-0.41-0.55-0.44-0.91c-0.32-3.36-1.67-6.23-4.05-8.62c-2.38-2.39-5.24-3.74-8.58-4.06 c-0.37-0.03-0.67-0.18-0.91-0.44c-0.24-0.26-0.36-0.58-0.36-0.95v-2.97c0-0.43,0.15-0.77,0.46-1.03c0.25-0.25,0.56-0.37,0.94-0.37 h0.11c2.34,0.19,4.57,0.78,6.71,1.77c2.13,0.99,4.03,2.32,5.68,4c1.67,1.66,2.99,3.56,3.98,5.7C24.43,28.89,25.02,31.13,25.21,33.48 z M36.43,33.52c0.03,0.4-0.1,0.74-0.39,1.03c-0.26,0.29-0.6,0.44-1.01,0.44h-3.14c-0.38,0-0.71-0.13-0.98-0.39 c-0.27-0.26-0.41-0.57-0.43-0.94c-0.18-3.16-0.91-6.15-2.21-8.99c-1.3-2.84-2.99-5.31-5.08-7.4c-2.08-2.09-4.54-3.79-7.37-5.1 c-2.83-1.31-5.81-2.05-8.96-2.25C6.51,9.93,6.2,9.79,5.95,9.52C5.69,9.24,5.56,8.92,5.56,8.56V5.41C5.56,5,5.71,4.66,6,4.4 C6.26,4.13,6.59,4,6.97,4h0.07c3.83,0.19,7.49,1.07,11,2.64c3.5,1.57,6.61,3.73,9.33,6.47c2.73,2.73,4.88,5.85,6.45,9.37 C35.37,26,36.24,29.68,36.43,33.52z" fill="#FF6B00"/></svg></a>
    </div>
  </Layout>
)
}
export const Head = () => <Seo title="Перейти на сайт Dosug.cz (Досуг.цз)">
<meta name="yandex" content="noindex, nofollow"/></Seo>
export default IndexPage
export const query = graphql`
query MyQuery {
  file(relativePath: {eq: "logo.png"}) {
    childImageSharp {
      fluid(
        maxHeight: 98
        maxWidth: 350
        pngQuality: 100
        quality: 100
        toFormat: AVIF
        webpQuality: 100
        srcSetBreakpoints: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700]
      ) {
        srcWebp
        srcSetWebp
        presentationHeight
        presentationWidth
        src
        srcSet
      }
    }
  }
}
`